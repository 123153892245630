// components/Experience/ExperienceManager/ExperienceManager.jsx

import React, { Suspense, useEffect, useState, useCallback } from "react";
import { useLocation } from "react-router-dom";
import { useThree } from "@react-three/fiber";
import useStore from "../../../stores/store";
import { getExperience } from "../ExperienceSystem/ExperienceSystem";
import ErrorBoundary from "../../../utils/ErrorBoundary";

function DefaultSceneFallback() {
  return null;
}

function ExperienceManager({ viewport }) {
  const location = useLocation();
  const { scene } = useThree();
  const [currentExperience, setCurrentExperience] = useState(null);
  const [isExperienceLoaded, setIsExperienceLoaded] = useState(false);
  const [isTransitionPending, setIsTransitionPending] = useState(false);

  const {
    theme,
    currentScene,
    isTransitioning,
    isLoading,
    setLoading,
    completeTransition,
    completeInitialLoad,
    isInitialLoad,
    startTransition,
    setScene,
  } = useStore();

  const handleSceneTransition = useCallback(
    (nextScene) => {
      setLoading(true);
      setIsTransitionPending(true);

      // Get the transition type from the target scene's config
      const nextExperience = getExperience(nextScene);
      console.log(
        "Starting transition with config:",
        nextExperience?.transition
      ); // Debug

      startTransition(nextScene);
    },
    [setLoading, startTransition]
  );

  useEffect(() => {
    const handleRouteChange = () => {
      const path = location.pathname.slice(1);
      const experience = getExperience(null, path);

      if (!experience) {
        console.error(`No experience found for path: ${path}`);
        return;
      }

      if (experience.key !== currentScene?.toLowerCase()) {
        if (currentScene) {
          handleSceneTransition(experience.key);
        } else {
          setLoading(true);
          setScene(experience.key);
        }
      }
    };

    handleRouteChange();
  }, [location.pathname, currentScene, handleSceneTransition, setScene]);

  useEffect(() => {
    if (!currentScene) return;

    const loadExperience = async () => {
      try {
        const experience = getExperience(currentScene);
        if (!experience?.Scene) {
          throw new Error(`Invalid experience for scene: ${currentScene}`);
        }

        setCurrentExperience(experience);
        setIsExperienceLoaded(true);

        if (isInitialLoad) {
          setTimeout(completeInitialLoad, 500);
        } else if (isTransitioning && isTransitionPending) {
          setIsTransitionPending(false);
          completeTransition();
        }
      } catch (error) {
        console.error("Failed to load experience:", error);
        setLoading(false);
        setIsTransitionPending(false);
      }
    };

    loadExperience();
  }, [
    currentScene,
    isInitialLoad,
    isTransitioning,
    completeInitialLoad,
    completeTransition,
    setLoading,
  ]);

  const handleSceneLoad = useCallback(() => {
    if (!isTransitionPending && !isInitialLoad) {
      setLoading(false);
    }
  }, [isTransitionPending, isInitialLoad, setLoading]);

  if (!currentExperience?.Scene) {
    return <DefaultSceneFallback />;
  }

  const { Scene } = currentExperience;
  const transitionValues = useStore.getState().getTransitionValues();

  return (
    <ErrorBoundary fallback={<DefaultSceneFallback />}>
      <Suspense fallback={<DefaultSceneFallback />}>
        <group visible={transitionValues.opacity > 0}>
          <Scene
            isTransitioning={isTransitioning}
            transitionValues={transitionValues}
            viewport={viewport}
            theme={theme}
            isInitialLoad={isInitialLoad}
            onLoad={handleSceneLoad}
          />
        </group>
      </Suspense>
    </ErrorBoundary>
  );
}

export default ExperienceManager;
