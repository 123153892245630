// components/Experience/ExperienceUI/ExperienceUI.jsx

import React from "react";
import styled from "styled-components";
import useStore from "../../../stores/store";
import Loader from "../../Loader/Loader";
import { getExperience } from "../ExperienceSystem/ExperienceSystem";

const UIWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 10;

  & > * {
    pointer-events: auto;
  }
`;

const ExperienceUI = () => {
  const currentScene = useStore((state) => state.currentScene);
  const isTransitioning = useStore((state) => state.isTransitioning);
  const isLoading = useStore((state) => state.isLoading);

  // Show loader when loading
  if (isLoading) {
    return (
      <UIWrapper>
        <Loader />
      </UIWrapper>
    );
  }

  // Hide UI during transitions
  if (isTransitioning) {
    return null;
  }

  // Get the experience definition and its UI
  const experience = getExperience(currentScene);
  if (!experience?.UI) {
    return null;
  }

  return <UIWrapper>{experience.UI}</UIWrapper>;
};

export default ExperienceUI;
