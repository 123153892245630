// experiences/Configurator/ConfiguratorUI.jsx

import React, { useState } from "react";
import styled from "styled-components";
import useStore from "../../stores/store";
import useConfiguratorStore from "./configuratorStore";
import { hexToRgba } from "../../utils/hexToRGBA";

const Controls = styled.div`
  position: fixed;
  right: 10%;
  top: 25%;
  width: 280px;
  border-radius: 8px;
  padding: 1rem;
  color: ${(props) => props.theme.textColorLight};
  z-index: 900;
  pointer-events: auto;
`;

const Section = styled.div`
  margin-bottom: 0.75rem;

  &:last-child {
    margin-bottom: 0;
  }
`;

const SectionTitle = styled.h3`
  font-size: 1.2rem;
  font-weight: 600;
  margin: 0 0 0.75rem 0;
  color: ${(props) => props.theme.textColorPrimary};
`;

const MaterialControl = styled.div`
  margin-bottom: 0.5rem;
  background: ${(props) => props.theme.bodyBgPrimary};
  border: 1px solid ${(props) => props.theme.primaryColor};
  border-radius: 6px;
  overflow: hidden;
  &:last-child {
    margin-bottom: 0;
  }
`;

const MaterialHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0.75rem;
  cursor: pointer;
  background: ${(props) => props.theme.secondaryColor};
  user-select: none;

  &:hover {
    background: ${(props) => props.theme.tertiaryColor};
  }
`;

const HeaderLeft = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const MaterialName = styled.div`
  font-weight: 500;
  font-size: 0.875rem;
`;

const Arrow = styled.div`
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.textColorSecondary};
  transform: rotate(${(props) => (props.isOpen ? "180deg" : "0deg")});
  transition: transform 0.2s ease;

  svg {
    width: 12px;
    height: 12px;
  }
`;

const MaterialContent = styled.div`
  padding: 0.75rem;
  border-top: 1px solid ${(props) => props.theme.borderColorPrimary};
  display: ${(props) => (props.isOpen ? "block" : "none")};
`;

const SliderGroup = styled.div`
  margin-bottom: 0.5rem;
  &:last-child {
    margin-bottom: 0;
  }
`;

const SliderLabel = styled.label`
  display: block;
  font-size: 0.75rem;
  margin-bottom: 0.25rem;
  color: ${(props) => props.theme.textColorPrimary};
`;

const Slider = styled.input`
  width: 100%;
  margin: 0;

  &[type="range"] {
    -webkit-appearance: none;
    background: ${(props) => hexToRgba(props.theme.primaryColor, 0.5)};
    border-radius: 3px;
    height: 3px;
    outline: none;

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: ${(props) => props.theme.accentColorPrimary};
      cursor: pointer;
      border: none;
    }

    &::-moz-range-thumb {
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: ${(props) => props.theme.accentColorPrimary};
      cursor: pointer;
      border: none;
    }
  }
`;

const ColorInput = styled.input`
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &::-webkit-color-swatch-wrapper {
    padding: 0;
  }
  &::-webkit-color-swatch {
    border: none;
    border-radius: 4px;
  }
`;

const Content = styled.div`
  position: fixed;
  left: 10%;
  top: 25%;
  width: 350px;
  border-radius: 8px;
  padding: 1rem;
  color: ${(props) => props.theme.textColorPrimary};
  z-index: 900;

  p {
    margin-bottom: 1rem;
  }
`;

const MaterialControls = ({ type, properties, onUpdate, isOpen, onToggle }) => {
  const getColorValue = (color) => {
    if (!color) return "#ffffff";
    return color.isColor ? "#" + color.getHexString() : color;
  };

  return (
    <MaterialControl>
      <MaterialHeader onClick={onToggle}>
        <HeaderLeft>
          <Arrow isOpen={isOpen}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <polyline points="6 9 12 15 18 9"></polyline>
            </svg>
          </Arrow>
          <MaterialName>
            {type.charAt(0).toUpperCase() + type.slice(1)}
          </MaterialName>
        </HeaderLeft>
        <ColorInput
          type="color"
          value={getColorValue(properties.color)}
          onChange={(e) => onUpdate(type, { color: e.target.value })}
          onClick={(e) => e.stopPropagation()}
        />
      </MaterialHeader>
      <MaterialContent isOpen={isOpen}>
        <SliderGroup>
          <SliderLabel>Gloss</SliderLabel>
          <Slider
            type="range"
            min="0"
            max="1"
            step="0.01"
            value={properties.roughness || 0}
            onChange={(e) =>
              onUpdate(type, { roughness: parseFloat(e.target.value) })
            }
          />
        </SliderGroup>
        <SliderGroup>
          <SliderLabel>Metalness</SliderLabel>
          <Slider
            type="range"
            min="0"
            max="1"
            step="0.01"
            value={properties.metalness || 0}
            onChange={(e) =>
              onUpdate(type, { metalness: parseFloat(e.target.value) })
            }
          />
        </SliderGroup>
      </MaterialContent>
    </MaterialControl>
  );
};

const ConfiguratorUI = () => {
  const { theme } = useStore();
  const { materials, updateMaterial } = useConfiguratorStore();
  const [openMaterial, setOpenMaterial] = useState(null);

  const handleToggle = (materialType) => {
    setOpenMaterial(openMaterial === materialType ? null : materialType);
  };

  return (
    <div style={{ pointerEvents: "auto" }}>
      <Content theme={theme}>
        <h2>Project Status:</h2>
        <p>
          For now, this basic configurator uses a simple, low-poly mesh with no
          textures.
        </p>
        <p>
          I know, we can do so much more but, it's going to stay this way
          (boring) a bit longer...
        </p>
        <p>
          Today I'm using this project to test transitions between 3D
          experiences.
        </p>
        <p>
          After I implement those transitions, I'll revisit this configurator,
          replace the model, and add some more interesting features.
        </p>
        <p>Until then, I'm leaving it ugly and ignoring responsiveness 😁.</p>
      </Content>
      <Controls theme={theme}>
        <Section>
          <SectionTitle>Material Controls</SectionTitle>
          {Object.entries(materials).map(([type, properties]) => (
            <MaterialControls
              key={type}
              type={type}
              properties={properties}
              onUpdate={updateMaterial}
              isOpen={openMaterial === type}
              onToggle={() => handleToggle(type)}
            />
          ))}
        </Section>
      </Controls>
    </div>
  );
};

export default ConfiguratorUI;
