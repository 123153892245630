// components/Experience/Experience.jsx

import React, { Suspense } from "react";
import { Canvas } from "@react-three/fiber";
import { OrthographicCamera } from "@react-three/drei";
import useStore from "../../stores/store";
import { useViewport } from "../../context/ViewportContext";
import ExperienceUI from "./ExperienceUI/ExperienceUI";
import styled from "styled-components";
import ErrorBoundary from "../../utils/ErrorBoundary";
import ExperienceManager from "./ExperienceManager/ExperienceManager";
import SceneTransition from "../SceneTransition/SceneTransition";
import Loader from "../Loader/Loader";
import { TransitionType } from "../../constants/transitions";

const LoaderWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) => props.theme.bodyBgPrimary};
  z-index: 1000;
`;

const LoadingFallback = () => (
  <LoaderWrapper>
    <div>Loading...</div>
  </LoaderWrapper>
);

function CanvasContent() {
  const theme = useStore((state) => state.theme);
  const isTransitioning = useStore((state) => state.isTransitioning);
  const transitionProgress = useStore((state) => state.transitionProgress);
  const transitionType = useStore((state) => state.transitionType);
  const getTransitionValues = useStore((state) => state.getTransitionValues);
  const viewport = useViewport();
  const transitionValues = getTransitionValues();

  const getTransitionShaderType = (type) => {
    // Log the incoming type to debug
    console.log("Transition type:", type);

    switch (type) {
      case "FADE":
        return 0;
      case "SLIDE_LEFT":
        return 1;
      case "SLIDE_RIGHT":
        return 2;
      case "SLIDE_UP":
        return 3;
      case "SLIDE_DOWN":
        return 4;
      case "SPIRAL":
        return 4;
      default:
        return 0;
    }
  };

  return (
    <group>
      <color attach="background" args={[theme.bodyBgPrimary]} />
      <OrthographicCamera makeDefault position={[0, 0, 100]} zoom={1} />
      <ExperienceManager
        transitionValues={transitionValues}
        viewport={viewport}
      />
      <SceneTransition
        color={theme.bodyBgPrimary}
        transition={isTransitioning}
        progress={transitionProgress}
        type={getTransitionShaderType(transitionType)}
      />
    </group>
  );
}

function Experience() {
  const theme = useStore((state) => state.theme);
  const isLoading = useStore((state) => state.isLoading);

  return (
    <ErrorBoundary>
      <ExperienceUI />
      {isLoading && <Loader isLoading={true} />}
      <Canvas shadows className="canvas">
        <Suspense fallback={null}>
          <CanvasContent />
        </Suspense>
      </Canvas>
    </ErrorBoundary>
  );
}

export default Experience;
