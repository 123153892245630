// stores/slices/sceneTransitionSlice.js

import { TRANSITION_TIMING, TransitionType } from "../../constants/transitions";
import { getExperienceForScene } from "../../constants/sceneExperiences";

const initialState = {
  currentScene: null,
  targetScene: null,
  isTransitioning: true,
  transitionProgress: 1,
  transitionType: "FADE",
  transitionError: null,
  previousScene: null,
  isLoading: true,
  isInitialLoad: true,
  transitionPhase: "none", // 'out', 'switching', 'in', or 'none'
};

const createSceneTransitionSlice = (set, get) => ({
  ...initialState,

  setLoading: (loading) => {
    set({ isLoading: loading });
  },

  setScene: (sceneName) => {
    console.log("Setting scene:", sceneName);
    const currentState = get();

    // Only switch scenes if we're in the right phase
    if (currentState.transitionPhase === "switching") {
      set({
        currentScene: sceneName,
        transitionPhase: "in",
      });
    }
  },

  initializeScene: () => {
    console.log("Initializing scene from route");
    const path = window.location.hash.slice(2) || "/";
    console.log("Current path:", path);

    let initialScene = "DODECAHEDRON";

    if (path.includes("projects/hexmap")) {
      initialScene = "HEX_CRAFT";
    } else if (path.includes("projects/configurator")) {
      initialScene = "CONFIGURATOR";
    }

    console.log("Setting initial scene to:", initialScene);

    set({
      currentScene: initialScene,
      isTransitioning: true,
      transitionProgress: 1,
      isLoading: true,
      isInitialLoad: true,
      transitionPhase: "none",
    });

    return initialScene;
  },

  startTransition: async (nextScene) => {
    const currentState = get();

    if (!nextScene || nextScene === currentState.currentScene) {
      console.warn("Invalid transition request:", {
        nextScene,
        currentScene: currentState.currentScene,
      });
      return;
    }

    // Start transition out
    set({
      isTransitioning: true,
      targetScene: nextScene,
      previousScene: currentState.currentScene,
      transitionProgress: 0,
      transitionError: null,
      isLoading: true,
      transitionPhase: "out",
    });

    let startTime = performance.now();
    const animateOut = () => {
      const currentTime = performance.now();
      const elapsed = currentTime - startTime;
      const progress = Math.min(elapsed / TRANSITION_TIMING.DURATION, 1);

      set((state) => ({
        transitionProgress: progress,
      }));

      if (progress < 1) {
        requestAnimationFrame(animateOut);
      } else {
        // When fully transitioned out, set phase to switching
        set((state) => ({
          transitionPhase: "switching",
        }));
      }
    };

    requestAnimationFrame(animateOut);
  },

  completeTransition: () => {
    const currentState = get();
    if (currentState.isInitialLoad) return;

    // Only proceed if we're in the 'in' phase
    if (currentState.transitionPhase !== "in") return;

    let startTime = performance.now();
    const animateIn = () => {
      const currentTime = performance.now();
      const elapsed = currentTime - startTime;
      const progress = Math.min(elapsed / TRANSITION_TIMING.DURATION, 1);

      set((state) => ({
        transitionProgress: 1 - progress,
      }));

      if (progress < 1) {
        requestAnimationFrame(animateIn);
      } else {
        set({
          targetScene: null,
          isTransitioning: false,
          transitionProgress: 0,
          isLoading: false,
          transitionPhase: "none",
        });
      }
    };

    requestAnimationFrame(animateIn);
  },

  completeInitialLoad: () => {
    let startTime = performance.now();
    const fadeIn = () => {
      const currentTime = performance.now();
      const elapsed = currentTime - startTime;
      const progress = Math.min(elapsed / TRANSITION_TIMING.DURATION, 1);

      set((state) => ({
        transitionProgress: 1 - progress,
      }));

      if (progress < 1) {
        requestAnimationFrame(fadeIn);
      } else {
        set({
          isTransitioning: false,
          transitionProgress: 0,
          isLoading: false,
          isInitialLoad: false,
          transitionPhase: "none",
        });
      }
    };

    requestAnimationFrame(fadeIn);
  },

  startTransition: async (nextScene) => {
    const currentState = get();

    if (!nextScene || nextScene === currentState.currentScene) {
      console.warn("Invalid transition request:", {
        nextScene,
        currentScene: currentState.currentScene,
      });
      return;
    }

    // Start transition
    set({
      isTransitioning: true,
      targetScene: nextScene,
      previousScene: currentState.currentScene,
      transitionProgress: 0,
      transitionError: null,
      isLoading: true,
    });

    // Start transition out animation
    let startTime = performance.now();
    const animateOut = () => {
      const currentTime = performance.now();
      const elapsed = currentTime - startTime;
      const progress = Math.min(elapsed / TRANSITION_TIMING.DURATION, 1);

      set((state) => ({
        transitionProgress: progress,
      }));

      if (progress < 1) {
        requestAnimationFrame(animateOut);
      } else {
        set((state) => ({
          currentScene: state.targetScene,
        }));
      }
    };

    requestAnimationFrame(animateOut);
  },

  completeTransition: () => {
    const currentState = get();
    if (currentState.isInitialLoad) return;

    let startTime = performance.now();
    const animateIn = () => {
      const currentTime = performance.now();
      const elapsed = currentTime - startTime;
      const progress = Math.min(elapsed / TRANSITION_TIMING.DURATION, 1);

      set((state) => ({
        transitionProgress: 1 - progress,
      }));

      if (progress < 1) {
        requestAnimationFrame(animateIn);
      } else {
        set({
          targetScene: null,
          isTransitioning: false,
          transitionProgress: 0,
          isLoading: false, // Only remove loading state after transition is fully complete
        });
      }
    };

    requestAnimationFrame(animateIn);
  },

  // Cancel the current transition

  cancelTransition: () => {
    set((state) => ({
      ...initialState,
      currentScene: state.previousScene || state.currentScene,
      isLoading: false,
      isInitialLoad: false,
      transitionPhase: "none",
    }));
  },

  setTransitionError: (error) => {
    set({
      transitionError: error,
      isTransitioning: false,
      targetScene: null,
      transitionProgress: 0,
      isLoading: false,
      isInitialLoad: false,
      transitionPhase: "none",
    });
  },

  getTransitionValues: () => {
    const state = get();
    const progress = state.transitionProgress;

    const currentExperience = getExperienceForScene(state.currentScene);
    const targetExperience = state.targetScene
      ? getExperienceForScene(state.targetScene)
      : null;

    if (state.isTransitioning && targetExperience?.transition?.interpolate) {
      return targetExperience.transition.interpolate(progress);
    }

    // Default transitions based on type
    switch (state.transitionType) {
      case TransitionType.FADE:
        return {
          opacity: state.isTransitioning ? 1 - progress : 1,
          scale: 1,
          position: [0, 0, 0],
        };

      case TransitionType.SCALE:
        return {
          opacity: 1,
          scale: state.isTransitioning ? 1 - progress * 0.5 : 1,
          position: [0, 0, 0],
        };

      case TransitionType.SLIDE_LEFT:
        return {
          opacity: 1,
          scale: 1,
          position: [state.isTransitioning ? -progress * 100 : 0, 0, 0],
        };

      case TransitionType.SLIDE_RIGHT:
        return {
          opacity: 1,
          scale: 1,
          position: [state.isTransitioning ? progress * 100 : 0, 0, 0],
        };

      case TransitionType.SLIDE_UP:
        return {
          opacity: 1,
          scale: 1,
          position: [0, state.isTransitioning ? progress * 100 : 0, 0],
        };

      case TransitionType.SLIDE_DOWN:
        return {
          opacity: 1,
          scale: 1,
          position: [0, state.isTransitioning ? -progress * 100 : 0, 0],
        };

      case TransitionType.ZOOM_IN:
        return {
          opacity: 1,
          scale: state.isTransitioning ? 1 + progress : 1,
          position: [0, 0, 0],
        };

      case TransitionType.ZOOM_OUT:
        return {
          opacity: 1,
          scale: state.isTransitioning ? 1 - progress * 0.5 : 1,
          position: [0, 0, 0],
        };

      default:
        return {
          opacity: 1,
          scale: 1,
          position: [0, 0, 0],
        };
    }
  },

  // Get the current transition state
  getTransitionState: () => {
    const state = get();
    return {
      isTransitioning: state.isTransitioning,
      currentScene: state.currentScene,
      targetScene: state.targetScene,
      progress: state.transitionProgress,
      type: state.transitionType,
      error: state.transitionError,
      isLoading: state.isLoading,
      isInitialLoad: state.isInitialLoad,
      phase: state.transitionPhase,
    };
  },

  resetTransition: () => {
    set({
      ...initialState,
      isInitialLoad: false,
    });
  },
});

export default createSceneTransitionSlice;
