// components/SceneTransition/SceneTransition.jsx

import { Hud, OrthographicCamera } from "@react-three/drei";
import { useFrame, useThree } from "@react-three/fiber";
import React, { useRef } from "react";
import * as THREE from "three";
import "./transitions/TransitionMaterial";
import useStore from "../../stores/store";
import { getExperience } from "../Experience/ExperienceSystem/ExperienceSystem";

function SceneTransition({ transition, color }) {
  const materialRef = useRef();
  const { size } = useThree();
  const currentScene = useStore((state) => state.currentScene);
  const experience = getExperience(currentScene);
  const transitionType = experience?.transition?.type ?? 0;
  const transitionSpeed = experience?.transition?.speed ?? 0.1;
  const setScene = useStore((state) => state.setScene);
  const targetScene = useStore((state) => state.targetScene);
  const completeTransition = useStore((state) => state.completeTransition);

  useFrame((state, delta) => {
    if (materialRef.current) {
      const currentProgress = materialRef.current.uProgression;
      const targetValue = transition ? 0 : 1;

      materialRef.current.uProgression = THREE.MathUtils.lerp(
        currentProgress,
        targetValue,
        transitionSpeed
      );

      // If we're transitioning out and have reached the target value
      if (transition && Math.abs(currentProgress - 1) < 0.01 && targetScene) {
        setScene(targetScene);
      }

      // If we're transitioning in and have reached the target value
      if (!transition && Math.abs(currentProgress) < 0.01) {
        completeTransition();
      }

      materialRef.current.uResolution.set(size.width, size.height);
    }
  });

  return (
    <Hud>
      <OrthographicCamera
        makeDefault
        top={1}
        right={1}
        bottom={-1}
        left={-1}
        near={0}
        far={1}
      />
      <mesh>
        <planeGeometry args={[2, 2]} />
        <transitionMaterial
          ref={materialRef}
          uColor={color}
          transparent
          depthTest={false}
          uProgression={0}
          uTransitionType={transitionType}
          uResolution={new THREE.Vector2(size.width, size.height)}
        />
      </mesh>
    </Hud>
  );
}

export default SceneTransition;
